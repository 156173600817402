@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Noto Sans Thai Variable', sans-serif;
}


.Toastify__toast-body {
    color: #333;
}

.side-nav li:not(.main-page-home) .active {
    background-color: rgb(249 249 249);
    border-left-width: 4px;
    --tw-bg-opacity: 1;
    color: #1025a4;
    border-color: #1025a4;
}
.MuiSnackbarContent-root {
    background: #fff !important;
    color: #333 !important;
}

.error-doc * {
    z-index: 999;
    position: relative;
}

.error-doc:before {
    content: "404";
    position: absolute;
    top: 50%;
    left: 50%;
    color: #f3f3f3;
    font-weight: 700;
    font-size: 20em;
    line-height: 1;
    transform: translate(-50%, -50%);
}

.logo svg {
    max-width: 100%;
}

.main-side-bar {
/*
    width: 220px;
    min-width: 220px;
    */
}

.main-side-bar.collapsed .menu-link-warp, .main-side-bar.collapsed .left-sidebar-title {
    display: none;
}
.main-side-bar.collapsed {
    width: 80px;
    min-width: 80px;
}

.main-side-bar.collapsed .logo svg {
    padding: 0.84em 0;
}

.main-side-bar.collapsed li {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.main-side-bar.collapsed li:not(.main-page-home) .active .menu-svg-warp {
    transform: translate(-4px, 0px);
}

.fc .fc-button-primary {
    background-color: #3730a3 !important;
    border-color: #3730a3 !important;
    color: var(--fc-button-text-color);
}
.fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width)/2) solid #3730a3 !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #1025a4;
}
.iupload-warp label {
    max-width: 100% !important;
    height: 140px;
    padding: 2rem;
    margin-bottom: 0.5em;
    border: dashed 2px #b5b5b5;
}
.iupload-warp path {
    fill: #282828;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(40 109 255 / 7%) !important;
}

td .MuiInputBase-root:before {
    border: none !important;
}

.remove-spin::-webkit-inner-spin-button,
  .remove-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .center-input input {
    text-align: center;
}
.custom-card-bg:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(134 132 170) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(121 96 172) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}
.custom-card-bg-2:before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, rgb(255 140 32) -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}
.custom-card-bg-2:after {
    content: "";
    position: absolute;
    width: 221px;
    height: 219px;
    background: linear-gradient(210.04deg, rgb(253 112 112) -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
}
.hidden-edit-menu{
    display: none;
}
.table-row-tr:hover  .hidden-edit-menu{
    display: block;
}
@media only screen and (max-width: 768px) {
    .error-doc h1 {
        font-size: 1.2em;
    }
    .error-doc:before {
        font-size: 12em;
    }
    .main-doc:before {
        font-size: 4em;
    }
}


.ck-editor__editable {
    /* apply your styles here */
    background: #f2f2f2;
    min-height: 300px;
    /* more styles as needed */
}

.ck-content h1 {
    font-size: 2em;
    font-weight: 600;
}
.ck-content h2 {
    font-size: 1.5em;
    font-weight: 600;
}
.ck-content h3 {
    font-size: 1.17em;
    font-weight: 600;
}
.ck-content h4 {
    font-size: 1em;
    font-weight: 600;
}
.ck-content h5 {
    font-size: 0.83em;
    font-weight: 600;
}
.ck-content h6 {
    font-size: 0.67em;
    font-weight: 600;
}
.ck-content ul {
    list-style-type: disc;
    list-style-position: inside;
}
.ck-content ol {
    list-style-type: decimal;
    list-style-position: inside;
}

.ga-check:checked + img {
    border: 5px solid rgb(59 130 246); 
}
.ga-check:checked  {
    display: inline-block;
}
.ga-item:hover .ga-check:checked + img{
    border: 5px solid #005cc8; 
}

.tech-spacs .tech-spacs-contents ul li {
    width: 100%;
    min-height: 48px;
    border-bottom: 1px solid #e4e4e4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.tech-spacs .tech-spacs-title {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 0.5em;
}
.tech-spacs {
    padding-top: 52px;
}
 .tech-spacs .tech-spacs-contents .file-list li:after, .tech-spacs .tech-spacs-contents .file-list li:before, .tech-spacs .tech-spacs-contents ul li:after, .tech-spacs .tech-spacs-contents ul li:before {
    content: " ";
    display: table;
}
.tech-spacs .tech-spacs-contents .file-list li:after, .tech-spacs .tech-spacs-contents ul li:after {
    clear: both;
}
.tech-spacs-contents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1rem;
}
.tech-spacs-contents dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.75em;
    border-bottom: solid 1px #ccc;
}
.tech-spacs-contents dt {
    font-weight: 600;
}
.tech-spacs-contents dd, .tech-spacs-contents dd p {
    text-align: right;
}
.side-nav li:not(.main-page-home) .active span {
    transform: translateX(-4px);
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
abbr.required {
    display: none;
}

@media only screen and (max-width: 768px) {
    .main-side-bar.collapsed {
        display: none;
    }
    .main-side-bar {
        min-width: 300px;
    }
}